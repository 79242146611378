@import url(https://fonts.googleapis.com/css2?family=Yellowtail&display=swap);
:root {
    --clr-black: #000000;
    --clr-white: #FFFFFF;
}

html {
    font-family: "Yellowtail" !important;
}

body {
    background: #FFFFFF;
    background: var(--clr-white);
    color: #000000;
    color: var(--clr-black);
}

main {
    margin-top: 200px;
}

.app-logo{
    height: 27px;
}

